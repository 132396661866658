import { Allotment } from "allotment";
import "allotment/dist/style.css";
import ChatUI from './ChatUI';
import React, { useState, useEffect, Fragment } from 'react';
import OpenAI from 'openai';
import QuestionDisplay from "./QuestionDisplay";
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from './config/firebase';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { auth } from './config/firebase';
import { User, signOut } from "firebase/auth";
import QuestionEditor from "./QuestionEditor";
import ReactMarkdown from 'react-markdown';
import { Disclosure, Menu, Transition, Dialog, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon, QueueListIcon, ChevronLeftIcon, ListBulletIcon, MoonIcon } from '@heroicons/react/20/solid'
import { Typography, IconButton, Tooltip } from '@mui/material';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png'
import Timer from "./Timer";
import { useRef } from 'react'
import { Dialog as MUIDialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconEdit, IconLogout, IconSettings, IconX } from '@tabler/icons-react';
import Editor from "@monaco-editor/react";
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { PencilIcon, EyeIcon, EyeSlashIcon, UserIcon } from '@heroicons/react/24/outline'
import saveActivity from "./utils/saveActivity";
import { set } from "react-hook-form";
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { IconLayout, IconListDetails, IconArrowsShuffle, IconListNumbers, IconCopy } from "@tabler/icons-react";
import 'prismjs/themes/prism-tomorrow.css'; // Or any other Prism theme
import rehypePrism from 'rehype-prism-plus'
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import { DialogPanel, DialogTitle } from '@headlessui/react'
import { tutorPrompt } from "./Prompts";
import { collection, query, where, getDocs, increment } from 'firebase/firestore';
import CloseIcon from '@mui/icons-material/Close';


const Question = () => {

    const { questionId } = useParams();
    const [textAnswer, setTextAnswer] = useState('');
    const [codeAnswer, setCodeAnswer] = useState('');
    const [lastSavedText, setLastSavedText] = useState('');
    const [lastSavedCode, setLastSavedCode] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('python');
    const [leetCodeQuestion, setLeetCodeQuestion] = useState('');
    const [leetCodeTitle, setLeetCodeTitle] = useState('');
    const [leetCodeAnswer, setLeetCodeAnswer] = useState('');
    const [answerType, setAnswerType] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [createMethod, setCreateMethod] = useState('');
    const [createModel, setCreateModel] = useState('');
    const [user, setUser] = useState<User | null>(null);
    const [savedCode, setSavedCode] = useState('');
    const [aiEvalResult, setAiEvalResult] = useState<string | null>('');
    const [openModal, setOpenModal] = useState(false);
    const [fetchedCode, setFetchedCode] = useState<Array<{ code: string; aiEval: string, timestamp: string, language: string, model: string }> | null>(null);
    const [tags, setTags] = useState<string[]>([]);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [modelName, setModelName] = useState<string>('');
    const [isEditorMaximized, setIsEditorMaximized] = useState(false);
    const [isChatMaximized, setIsChatMaximized] = useState(false);
    const [isDisplayMaximized, setIsDisplayMaximized] = useState(false);
    const [open, setOpen] = useState(true)
    const [questionListOpen, setQuestionListOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const [apiKey, setApiKey] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newApiKey, setNewApiKey] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isAnonymized, setIsAnonymized] = useState(true);
    const [originalData, setOriginalData] = useState<QuestionType[]>([]);
    const [prevQuestion, setPrevQuestion] = useState<string | null>('');
    const [nextQuestion, setNextQuestion] = useState<string | null>('');
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number | null>(null);
    const [isActivated, setIsActivated] = useState(true);
    const [initialLoad, setInitialLoad] = useState(false);
    const [maxSubmissionDialogOpen, setMaxSubmissionDialogOpen] = useState(false);
    const [manuallyGenAnswer, setManuallyGenAnswer] = useState<string | null>('');
    const [genAnswerLoading, setGenAnswerLoading] = useState(false);
    const [isEditorFolded, setIsEditorFolded] = useState(false);
    const [isChatFolded, setIsChatFolded] = useState(false);
    const [copied, setCopied] = useState(false);
    const [apiKeyDialogOpen, setApiKeyDialogOpen] = useState(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
    const [messages, setMessages] = useState([
        {
            role: 'system',
            content: tutorPrompt
        },
        { role: 'assistant', content: "Hi there! If you need any assistance with this problem, I'd be happy to help!" }
    ]);

    useEffect(() => {
        // Update the initial prompt with the new leetCodeAnswer
        const updatedPrompt = `${tutorPrompt} \nHere is a reference solution to this question that you can use to determine if the user's solution is correct.` + leetCodeAnswer + `. Pay close attention to the differences between the user's solution and the reference solution.  Never let the user know that you are aware of the reference solution.`.trim().replace(/(\r\n|\n|\r)/gm, '');

        // console.log("updating messages with solution")
        // Update the first message in the messages array
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[0] = { role: 'system', content: updatedPrompt };
            return updatedMessages;
        });
    }, [leetCodeAnswer]);

    const navigate = useNavigate();


    const handleCopy = () => {
        const contentToCopy = answerType === 'Code' ? codeAnswer : textAnswer;
        navigator.clipboard.writeText(contentToCopy || '');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    };


    const minimizeEditorPane = () => {
        if (innerRef.current) {
            innerRef.current.resize([2, 1000]);
            setIsEditorFolded(true);
        }
    };

    const unminimizeEditorPane = () => {
        if (innerRef.current) {
            innerRef.current.reset();
            setIsEditorFolded(false);
        }
    };

    const minimizeChatPane = () => {
        if (innerRef.current) {
            innerRef.current.resize([1000, 2]);
            setIsChatFolded(true);
        }
    };

    const unminimizeChatPane = () => {
        if (innerRef.current) {
            innerRef.current.reset();
            setIsChatFolded(false);
        }
    };

    type QuestionType = {
        qRef: string;
        title: string;
        tags: string[];
        difficulty: string;
        answerType: string;
    };

    useEffect(() => {
        const userId = user?.uid || '';
        const storedApiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`) || '';
        const storedModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`) || '';
        setModelName(storedModelName);
        setApiKey(storedApiKey);
        setNewApiKey(storedApiKey);
        setIsActivated(storedApiKey !== '' && localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true');
    }, [user]);

    useEffect(() => {
        if (leetCodeTitle) {
            document.title = leetCodeTitle + ' | HinterviewGPT';
        } else {
            document.title = 'Question'; // Default title if leetCodeTitle is not available
        }
    }, [leetCodeTitle]);

    const ref: any = useRef(null);
    const innerRef: any = useRef(null);

    const logOut = async () => {
        try {
            await signOut(auth);
            setUser(null);
            navigate('/auth');
        } catch (err: any) {
            console.log(err);

        }
    };

    useEffect(() => {
        const checkAccess = async () => {
            if (!user) return;

            // 1. Check subscription status
            const subscriptionsRef = collection(db, 'customers', user.uid, 'subscriptions');
            const activeSubscriptions = query(subscriptionsRef, where('status', 'in', ['active', 'trialing']));
            const subscriptionSnap = await getDocs(activeSubscriptions);

            // If an active or trialing subscription exists
            if (!subscriptionSnap.empty) {
                console.log("User has an active or trialing subscription");
                setHasAccess(true);
                return;
            }

            // 2. Check for free trial eligibility (7 days after sign-up)
            const signUpDate = new Date(user.metadata.creationTime!); // Firebase stores sign-up date
            const currentDate = new Date();
            const timeDiff = currentDate.getTime() - signUpDate.getTime();
            const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

            // If within 7-day trial period
            if (daysSinceSignUp < 7) {
                setTrialRemaining(7 - daysSinceSignUp);
                console.log("User has a free trial");
                setHasAccess(true);
                return;
            }

            // If no active subscription and free trial expired, redirect to the no-access page
            console.log("User does not have access");
            navigate('/no-access');
        };

        if (user) {
            checkAccess();
        }
    }, [user, navigate]);

    useEffect(() => {
        const saveInterval = setInterval(() => {
            if (answerType === 'Text' && textAnswer !== lastSavedText) {
                localStorage.setItem(`savedText_${questionId}`, textAnswer);
                setLastSavedText(textAnswer);
            } else if (answerType === 'Code' && codeAnswer !== lastSavedCode) {
                localStorage.setItem(`savedCode_${questionId}`, codeAnswer);
                setLastSavedCode(codeAnswer);
            }
        }, 3000); // Save every 3 seconds

        return () => clearInterval(saveInterval); // Clean up the interval on component unmount
    }, [textAnswer, codeAnswer, answerType, questionId, lastSavedText, lastSavedCode]);

    useEffect(() => {
        // Retrieve the saved text or code answer when the questionId or answerType changes
        const savedText = localStorage.getItem(`savedText_${questionId}`);
        const savedCode = localStorage.getItem(`savedCode_${questionId}`);

        if (answerType === 'Text') {
            setTextAnswer(savedText || '');
        } else if (answerType === 'Code') {
            setCodeAnswer(savedCode || '');
        }
    }, [questionId, answerType]);

    const handleExportToWhiteboard = (code: string) => {
        if (answerType === 'Code') {
            setCodeAnswer(code);
        } else {
            setTextAnswer(code);
        }
    };

    // Function to handle closing the snackbar
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const notificationMethods = [
        { id: 'gpt-4o-mini-2024-07-18', title: 'GPT-4o mini', subtitle: 'Cost-efficient model based on gpt-4o-mini-2024-07-18.' },
        { id: 'gpt-4-turbo-2024-04-09', title: 'GPT-4 Turbo', subtitle: 'Legacy Model based on gpt-4-turbo-2024-04-09.' },
        { id: 'gpt-4o-2024-08-06', title: 'GPT-4o', subtitle: 'Most advanced model based on gpt-4o-2024-08-06.' },
    ]
    const getModelTitle = (id: string) => {
        const method = notificationMethods.find((method) => method.id === id);
        return method ? method.title : 'Unknown';
    };


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
                navigate('/auth');
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (user) {
            // Only call getLeetCodeQuestions if the user has been set
            getLeetCodeQuestions();
            // console.log(originalData);
        }
    }, [user, leetCodeQuestion]);

    const handleToggleTheme = () => {
        setIsDarkMode((prevMode) => !prevMode);
    };

    // const { questionId } = useParams();

    const handleModelChange = (id: string) => {
        setModelName(id);
        const userId = user?.uid || '';
        localStorage.setItem(`hinterviewGpt_modelName_${userId}`, id);
    };

    useEffect(() => {
        const fetchQuestion = async () => {
            if (questionId && user) {
                try {
                    const questionDocRef = doc(db, 'userQuestions', questionId);
                    const docSnap = await getDoc(questionDocRef);
                    if (docSnap.exists()) {

                        const data = docSnap.data();

                        if (data.user !== user.uid) {
                            // Handle unauthorized access
                            navigate('/error');
                            return;
                        }
                        setLeetCodeQuestion(data.body);
                        setLeetCodeAnswer(data.answer || '');
                        setLeetCodeTitle(data.title);
                        setAnswerType(data.answerType);
                        setTags(data.tags || []);
                        setCreateMethod(data.method || '');
                        setCreatedDate(data.createdAt || '');
                        setCreateModel(data.model || '');

                    } else {
                        navigate('/error'); // Handle if the question doesn't exist
                    }
                } catch (error) {
                    console.error('Error fetching question:', error);
                    navigate('/error');
                }
            }
        };

        if (questionId && user) {
            fetchQuestion();
        }
    }, [questionId, user]);


    const RenderHTMLContent: React.FC<{ content: string }> = ({ content }) => {
        return <div className="custom-html-content-2" dangerouslySetInnerHTML={{ __html: content }} />;
    };

    const preprocessLaTeX = (content: string) => {
        // Replace block-level LaTeX delimiters \[ \] with $$ $$


        const blockProcessedContent = content.replace(
            /\\\[(.*?)\\\]/gs,
            (_, equation) => `$$${equation}$$`,
        );
        // Replace inline LaTeX delimiters \( \) with $ $
        const inlineProcessedContent = blockProcessedContent.replace(
            /\\\((.*?)\\\)/gs,
            (_, equation) => `$${equation}$`,
        );
        return inlineProcessedContent;
    };

    const MarkdownViewer = ({ markdownContent }: { markdownContent: string }) => {
        const rehypePrismOptions = {
            showLineNumbers: true, // Example option
            ignoreMissing: true, // Example option
            defaultLanguage: 'c', // Example option
        };

        return (
            <ReactMarkdown
                remarkPlugins={[remarkMath]}
                rehypePlugins={[[rehypeKatex], [rehypePrism, rehypePrismOptions]]}
            >
                {preprocessLaTeX(markdownContent)}
            </ReactMarkdown>
        );
    };

    const handleClearConversation = () => {
        // Clear all messages except the first two
        setMessages([
            messages[0], // System prompt
            messages[1], // Initial assistant message
        ]);
    };

    const handleShuffle = () => {
        if (originalData.length > 0) {
            const randomIndex = Math.floor(Math.random() * originalData.length);
            const randomQuestion = originalData[randomIndex];
            navigate(`/question/${randomQuestion.qRef}`);
        }
    };

    const handleActivateApiKey = () => {
        const userId = user?.uid || '';
        localStorage.setItem(`hinterviewGpt_openAi_apiKey_${userId}`, newApiKey);
        localStorage.setItem(`hinterviewGPt_apiKey_isActivated_${userId}`, 'true');
        setApiKey(newApiKey);
        setIsActivated(true);
        setIsAnonymized(true);
    };

    const handleDeactivateApiKey = () => {
        const userId = user?.uid || '';
        localStorage.removeItem(`hinterviewGpt_openAi_apiKey_${userId}`);
        localStorage.setItem(`hinterviewGPt_apiKey_isActivated_${userId}`, 'false');
        setApiKey('');
        setIsActivated(false);
        setIsAnonymized(true);
    };

    const anonymizeApiKey = (key: string) => {
        return key ? '*'.repeat(key.length) : '';
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        setIsAnonymized(true);
    };

    const handleSaveCode = async () => {
        const userId = user?.uid || '';
        const apiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`);
        const isApiKeyActivated = localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true';
        const latestModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`);

        if (!isApiKeyActivated || !apiKey) {
            setApiKeyDialogOpen(true); // Open the API Key Error dialog
            return;
        }

        if (user && questionId && latestModelName) {
            const code = answerType === 'Code' ? codeAnswer : textAnswer;

            if (fetchedCode && fetchedCode.length > 9) {
                setMaxSubmissionDialogOpen(true);
                return;
            } else {
                setOpenModal(true);
                setAiEvalResult(null);

                const initialMessages: any[] = [
                    { role: 'system', content: `Question: ${leetCodeQuestion}` },
                    {
                        role: 'system', content: `The user has now submitted their solution which you will see in the next message starting with 'User's Solution:'. 
                         Determine if it is correct or not. 
                         Do not add anything to the users solution to make it correct.  
                         If it is not correct, provide the correct answer.
                         Do not add anything to the user's solution to make it correct, just take the raw user solution and determine if it is correct.
                         Be extra critical of the user's solution.
                         If it is a coding-based question, be extra careful that each and every variable in the solution is being used correctly and is updated correctly.  If it isn't then call this out to the user.  Use this correct version of the question as a reference.
                         ` + leetCodeAnswer + 'Check for differences between the reference solution and the user solution.'
                    },
                    { role: 'system', content: `User's Solution: ${code}` },
                ];

                const openai = new OpenAI({
                    apiKey: apiKey,
                    dangerouslyAllowBrowser: true
                });

                // Get the current date for usage tracking
                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
                const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

                // Check the current usage for the user
                let requestCount = 0;
                try {
                    const userUsageSnap = await getDoc(userUsageRef);
                    if (userUsageSnap.exists()) {
                        const data = userUsageSnap.data();
                        requestCount = data.models[latestModelName] || 0; // Get the current number of requests for the model
                        console.log('Current usage:', requestCount);
                    }

                    // Optionally check for plan limits (if you want to enforce limits)
                    // if (requestCount >= planLimitForModel) {
                    //     setErrorMessage('API request limit reached for the current month.');
                    //     return;
                    // }
                } catch (error) {
                    console.error('Error fetching user usage data:', error);
                    return;
                }


                let aiEval = ''; // Concatenate OpenAI response

                const chatCompletion = await openai.chat.completions.create({
                    messages: initialMessages,
                    model: latestModelName,
                    stream: true
                });

                for await (const part of chatCompletion) {
                    aiEval += part.choices[0]?.delta?.content || '';
                    setAiEvalResult(aiEval);
                }

                // If the request was successful, update the number of API requests in Firebase
                try {
                    await updateDoc(userUsageRef, {
                        [`models.${latestModelName}`]: increment(1)
                    });
                } catch (error: any) {
                    // If the document does not exist, create it with the initial request count
                    if (error.code === 'not-found') {
                        await setDoc(userUsageRef, {
                            year,
                            month,
                            models: {
                                [latestModelName]: 1
                            }
                        });
                    } else {
                        console.error("Error updating user usage:", error);
                    }
                }

                const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${questionId}`);
                const docSnap = await getDoc(userAttemptDocRef);

                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const options: Intl.DateTimeFormatOptions = { timeZone: userTimeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
                const formattedDate = currentDate.toLocaleDateString(undefined, options);

                let lang = selectedLanguage;

                if (answerType === 'Text') {
                    lang = 'text';
                }

                const userAttemptData = {
                    userID: user.uid,
                    questionID: questionId,
                    code: docSnap.exists()
                        ? [
                            ...docSnap.data().code,
                            { code, aiEval, timestamp: formattedDate, language: lang, model: getModelTitle(modelName) },
                        ]
                        : [{ code, aiEval, timestamp: formattedDate, language: lang, model: getModelTitle(modelName) }],
                };

                try {
                    if (docSnap.exists()) {
                        await updateDoc(userAttemptDocRef, userAttemptData);
                    } else {
                        await setDoc(userAttemptDocRef, userAttemptData);
                    }

                    const userSubmissionNumDocRef = doc(db, 'userSubmissionNum', user.uid);
                    const userSubmissionNumDoc = await getDoc(userSubmissionNumDocRef);
                    let submissionNumData;

                    if (userSubmissionNumDoc.exists()) {
                        const currentData = userSubmissionNumDoc.data();
                        const currentQuestionSubmissions = currentData.questionSubmissions || {};

                        submissionNumData = {
                            questionSubmissions: {
                                ...currentQuestionSubmissions,
                                [questionId]: (userAttemptData.code.length || 0),
                            },
                        };

                        await updateDoc(userSubmissionNumDocRef, submissionNumData);
                    } else {
                        submissionNumData = {
                            questionSubmissions: {
                                [questionId]: 1,
                            },
                        };

                        await setDoc(userSubmissionNumDocRef, submissionNumData);
                    }

                    if (answerType === 'Code') {
                        setSavedCode(codeAnswer);
                    } else if (answerType === 'Text') {
                        setSavedCode(textAnswer);
                    }

                    updateFetchedCode();
                    await saveActivity(user.uid, "submitCount");
                } catch (error) {
                    console.error('Error saving code:', error);
                }
            }
        }
    };



    const handleDeleteCode = async (submissionIndex: number) => {
        // console.log("inside delete code");
        if (user && questionId) {
            try {
                // Get the user's submission document reference
                const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${questionId}`);
                const docSnap = await getDoc(userAttemptDocRef);

                if (docSnap.exists()) {
                    // Get the current code array from the document
                    const currentCode = docSnap.data().code || [];

                    // Remove the submission at the specified index
                    const updatedCode = currentCode.filter((_: string, index: number) => index !== submissionIndex);

                    // Update the user's submission document with the modified code
                    await updateDoc(userAttemptDocRef, { code: updatedCode });

                    // Update 'userSubmissionNum' collection
                    const userSubmissionNumDocRef = doc(db, 'userSubmissionNum', user.uid);
                    const userSubmissionNumDoc = await getDoc(userSubmissionNumDocRef);

                    if (userSubmissionNumDoc.exists()) {
                        const currentData = userSubmissionNumDoc.data();
                        const currentQuestionSubmissions = currentData.questionSubmissions || {};

                        // Update the submission count for the specific question
                        const updatedQuestionSubmissions = {
                            ...currentQuestionSubmissions,
                            [questionId]: (updatedCode.length || 0),
                        };

                        await updateDoc(userSubmissionNumDocRef, { questionSubmissions: updatedQuestionSubmissions });
                    }

                    // Fetch and update the fetchedCode state
                    updateFetchedCode();
                } else {
                    console.log('No saved code found for the user.');
                }
            } catch (error) {
                console.error('Error deleting code:', error);
                // Handle error, maybe show a notification to the user
            }
        }
    };

    const genSolution = async () => {

        const userId = user?.uid || '';
        const apiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`);
        const isApiKeyActivated = localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true';
        const latestModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`);

        if (!isApiKeyActivated) {
            console.error('API key is not activated.');
            setApiKeyDialogOpen(true); // Open the API Key Error dialog
            return;
        }

        if (!apiKey) {
            console.error('API key is not available.');
            setApiKeyDialogOpen(true); // Open the API Key Error dialog
            return;
        }
        if (!questionId) {
            console.error('No question ID available.');
            return;
        }

        if (!latestModelName) {
            console.error('Selected model name not recognized.');
            setApiKeyDialogOpen(true); // Open the API Key Error dialog

            return;
        }
        if (user) {

            try {

                setGenAnswerLoading(true);

                const initialMessages: any[] = [
                    {
                        role: 'system', content: `You are an expert in solving job interview questions. Given the following job interview question, generate the correct answer to the question.  If the answer requires a diagram (for example, a system design diagram), draw it in plaintext. If there are multiple different correct solutions to this question generate at most 2 with the headings 'Solution #1' and 'Solution #2'"  
                       `
                    },
                    { role: 'system', content: `Question: ${leetCodeQuestion}` },

                ];

                const openai = new OpenAI({
                    apiKey: apiKey,
                    dangerouslyAllowBrowser: true
                });

                // Get the current date for usage tracking
                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
                const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

                // Check the current usage for the user
                let requestCount = 0;
                try {
                    const userUsageSnap = await getDoc(userUsageRef);
                    if (userUsageSnap.exists()) {
                        const data = userUsageSnap.data();
                        requestCount = data.models[latestModelName] || 0; // Get the current number of requests for the model
                        console.log('Current usage:', requestCount);
                    }

                    // Optionally check for plan limits (if you want to enforce limits)
                    // if (requestCount >= planLimitForModel) {
                    //     setErrorMessage('API request limit reached for the current month.');
                    //     return;
                    // }
                } catch (error) {
                    console.error('Error fetching user usage data:', error);
                    return;
                }


                let aiEval = ''; // Concatenate OpenAI response

                const chatCompletion = await openai.chat.completions.create({
                    messages: initialMessages,
                    model: latestModelName,
                    stream: false
                });

                const generatedAnswer = chatCompletion.choices[0]?.message?.content || '{}';

                setGenAnswerLoading(false);
                setLeetCodeAnswer(generatedAnswer);

                // If the request was successful, update the number of API requests in Firebase
                try {
                    await updateDoc(userUsageRef, {
                        [`models.${latestModelName}`]: increment(1)
                    });
                } catch (error: any) {
                    // If the document does not exist, create it with the initial request count
                    if (error.code === 'not-found') {
                        await setDoc(userUsageRef, {
                            year,
                            month,
                            models: {
                                [latestModelName]: 1
                            }
                        });
                    } else {
                        console.error("Error updating user usage:", error);
                    }
                }

                // Save generated answer to firestore according to question id and user id
                const questionDocRef = doc(db, 'userQuestions', questionId);
                await updateDoc(questionDocRef, {
                    answer: generatedAnswer // assuming the field that holds the solution is named 'answer'
                });
            } catch (error) {
                console.error('Error saving code:', error);
                // Handle error, maybe show a notification to the user
            }
        }
    };




    const updateFetchedCode = async () => {
        if (user && questionId) {
            try {
                const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${questionId}`);
                const docSnap = await getDoc(userAttemptDocRef);
                // console.log("UpdateFetchedCode called!");

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setFetchedCode(data.code || []);
                } else {
                    console.log('No saved code found for the user.');
                }
            } catch (error) {
                console.error('Error fetching saved code:', error);
                // Handle error, maybe show a notification to the user
            }
        }
    };

    useEffect(() => {
        const fetchSavedCode = async () => {

            if (user && questionId) {
                try {
                    const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${questionId}`);
                    const docSnap = await getDoc(userAttemptDocRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        // Assuming `code` is an array of objects with `code` and `aiEval` fields
                        setFetchedCode(data.code || []);
                    } else {
                        // Handle if the document doesn't exist
                        console.log('No saved code found for the user.');
                    }
                } catch (error) {
                    console.error('Error fetching saved code:', error);
                    // Handle error, maybe show a notification to the user
                }
            }
        };

        if (user && questionId) {
            fetchSavedCode();
        }
    }, [user, questionId]);

    useEffect(() => {
        const getPrevNextQuestion = async () => {

            const currentIndex = originalData.findIndex(question => question.qRef === questionId);

            if (currentIndex === -1) {
                return;
            }

            const prevQuestion = currentIndex > 0 ? originalData[currentIndex - 1].qRef : null;
            const nextQuestion = currentIndex < originalData.length - 1 ? originalData[currentIndex + 1].qRef : null;

            // console.log('Previous Question ID:', prevQuestion);
            // console.log('Next Question ID:', nextQuestion);

            setPrevQuestion(prevQuestion);
            setNextQuestion(nextQuestion);
            setCurrentQuestionIndex(currentIndex);

        };

        if (originalData && questionId) {
            getPrevNextQuestion();
        }
    }, [originalData, questionId]);

    useEffect(() => {
        handleClearConversation();
    }, [questionId]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleLanguageChange = (language: string) => {
        setSelectedLanguage(language);
    };

    const handleCodeRefresh = () => {
        setCodeAnswer('');
        setTextAnswer('');
    };

    function classNames(...classes: (string | false | null | undefined)[]) {
        return classes.filter(Boolean).join(' ')
    }

    const navigation = [
        { name: 'Question List', href: '', current: false },
    ]




    const getLeetCodeQuestions = async () => {
        // console.log("getLeetCodeQuestions called!");
        // console.log("user: ", user);

        if (!user) {
            return;
        }

        if (user) {
            try {

                const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
                const userQuestionsListDoc = await getDoc(userQuestionsListRef);

                if (userQuestionsListDoc.exists()) {
                    // Explicitly define the type for question_data
                    const userQuestionsList = userQuestionsListDoc.data().questions || [];

                    // Convert the 'question_data' field to an array of QuestionType
                    const queriedData: QuestionType[] = userQuestionsList.map((question: QuestionType) => {
                        const { title, difficulty, qRef, tags, answerType } = question;

                        return {
                            qRef,
                            title,
                            tags,
                            difficulty,
                            answerType,
                        };
                    });

                    // console.log("queriedData: ", queriedData);
                    setOriginalData(queriedData);

                }

            } catch (err) {
                console.error('Error getting documents: ', err);
            }
        }
    };

    const shouldMaximizeDisplay = isDisplayMaximized || (!isChatMaximized && !isEditorMaximized);


    return (

        <>
            <div className="h-screen bg-gray-100">
                <Disclosure as="nav" className="bg-gray-100">
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-2">
                                <div className="flex h-11 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex items-center justify-center h-screen">
                                            <img
                                                className="h-10 w-auto cursor-pointer"
                                                src={logo}
                                                onClick={() => navigate(`/activity`)}
                                                alt="Your Company"
                                            />
                                        </div>

                                        {/* Vertical Line Divider */}
                                        <div className="mx-1 h-4 w-px bg-gray-300"></div>

                                        <div className="hidden md:block">
                                            <div className="flex items-center justify-center h-screen ">

                                                <button
                                                    className="bg-gray-100 text-gray-700 px-2 py-2 rounded-lg hover:bg-gray-200 flex rounded-md px-3 py-2 text-sm font-medium items-center"
                                                    onClick={() => setQuestionListOpen(true)}
                                                >
                                                    <IconListNumbers className="h-5 w-5 mr-1 text-gray-500" aria-hidden="true" />
                                                    <span>Question List</span>
                                                </button>
                                                <Tooltip title="Prev Question" >
                                                    <button
                                                        className={classNames(
                                                            'px-3 py-2 rounded-lg flex items-center',
                                                            prevQuestion ? 'bg-gray-100 text-gray-700 hover:bg-gray-200' : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                                        )}
                                                        onClick={() => navigate(`/question/${prevQuestion}`)}
                                                        disabled={!prevQuestion}
                                                    >
                                                        <ChevronLeftIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Next Question" >
                                                    <span>
                                                        <button
                                                            className={classNames(
                                                                'px-3 py-2 rounded-lg flex items-center',
                                                                nextQuestion ? 'bg-gray-100 text-gray-700 hover:bg-gray-200' : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                                            )}
                                                            onClick={() => navigate(`/question/${nextQuestion}`)}
                                                            disabled={!nextQuestion}
                                                        >
                                                            <ChevronRightIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Shuffle" >
                                                    <button
                                                        type="button"
                                                        className="bg-gray-100 text-gray-700 px-3 py-2 mr-0 ml-0 rounded-lg hover:bg-gray-200"
                                                        onClick={handleShuffle}
                                                    >
                                                        <IconArrowsShuffle className="h-5 w-5 text-gray-500" />
                                                    </button>
                                                </Tooltip>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-center h-screen mr-6">
                                        <Timer />

                                    </div>



                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center justify-center h-screen">
                                            {/* <div className='text-sm font-medium mr-2 text-gray-700'>
                                                Model:
                                            </div> */}
                                            <Tooltip title="Change Model" PopperProps={{
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -6], // Change this to control the offset
                                                        },
                                                    },
                                                ],
                                            }}>

                                                <Menu as="div" className="relative">
                                                    <div>
                                                        <Menu.Button className="relative flex max-w-xs items-center text-sm bg-gray-100 text-gray-700 px-2 py-2 mr-0 border border-gray-300 rounded-lg hover:bg-gray-200"
                                                            onClick={() => setIsDialogOpen(true)}
                                                        >
                                                            {isActivated ? (
                                                                notificationMethods.find((method) => method.id === modelName)?.title
                                                            ) : (
                                                                <span className="text-red-500">Not Activated</span>
                                                            )}
                                                            <svg className="ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                            </svg>
                                                        </Menu.Button>
                                                    </div>
                                                </Menu>
                                            </Tooltip>

                                            <Tooltip title="Reset Layout" PopperProps={{
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -6], // Change this to control the offset
                                                        },
                                                    },
                                                ],
                                            }}>

                                                <button
                                                    type="button"
                                                    className="text-gray-700 px-2 py-2 mr-0 ml-2 rounded-lg hover:bg-gray-200"

                                                    onClick={() => {
                                                        ref.current?.reset();
                                                        innerRef.current?.reset();
                                                    }}
                                                >
                                                    <IconLayout className="h-5 w-5 rotate-180 text-gray-500" />
                                                </button>
                                            </Tooltip>

                                            <div className="flex items-center ml-0 mr-2">

                                                {/* Profile dropdown */}
                                                <Menu as="div" className="relative ml-2 flex-shrink-0">
                                                    <div>
                                                        <MenuButton className="relative flex rounded-full bg-white text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                            <span className="absolute -inset-1.5" />
                                                            <span className="sr-only">Open user menu</span>
                                                            {user?.photoURL ? (
                                                                <img
                                                                    className="h-6 w-6 rounded-full"
                                                                    src={user.photoURL}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <UserIcon className="h-7 w-7 rounded-full border text-gray-500" />
                                                            )}
                                                        </MenuButton>
                                                    </div>
                                                    <MenuItems
                                                        // transition
                                                        className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                                    >

                                                        <MenuItem>
                                                            {({ focus }) => (
                                                                <a
                                                                    href="" onClick={() => navigate('/account')}
                                                                    className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 flex items-center')}
                                                                >
                                                                    <IconSettings className="h-5 w-5 mr-1 text-gray-500" aria-hidden="true" />

                                                                    Account Settings
                                                                </a>
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem>
                                                            {({ focus }) => (
                                                                <a
                                                                    href="" onClick={logOut}
                                                                    className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 flex items-center')}
                                                                >
                                                                    <IconLogout className="h-5 w-5 mr-1 text-gray-500 ml-0.5" aria-hidden="true" />

                                                                    Sign out
                                                                </a>
                                                            )}
                                                        </MenuItem>
                                                    </MenuItems>
                                                </Menu>
                                            </div>
                                        </div>
                                        <Transition.Root show={isDialogOpen} as={Fragment}>
                                            <Dialog as="div" className="relative z-10" onClose={closeDialog}>
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                                </Transition.Child>

                                                <div className="fixed inset-0 z-10 overflow-y-auto">
                                                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                                        <Transition.Child
                                                            as={Fragment}
                                                            enter="ease-out duration-300"
                                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                            leave="ease-in duration-200"
                                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                        >
                                                            <Dialog.Panel className="relative transform overflow-hidden h-92 rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 overflow-y-auto max-h-[90vh]">
                                                                <div className="absolute top-0 right-0 pt-4 pr-4">
                                                                    <button
                                                                        type="button"
                                                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                        onClick={closeDialog}
                                                                    >
                                                                        <span className="sr-only">Close</span>
                                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                                    Model Settings
                                                                </Dialog.Title>
                                                                <div className="mt-1 relative">
                                                                    <label className="block text-sm font-medium text-gray-700 mb-1 mt-6">Select Model</label>
                                                                    <Menu as="div" className="relative inline-block text-left w-full">
                                                                        <div className="cursor-pointer hover:text-gray-900 rounded flex items-center justify-center">
                                                                            <MenuButton className="inline-flex w-full justify-between items-center rounded-md px-3 py-2 bg-white text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none">
                                                                                {notificationMethods.find((method) => method.id === modelName)?.title || 'Select Model'}
                                                                                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-500" />
                                                                            </MenuButton>
                                                                        </div>

                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <MenuItems className="absolute right-0 z-10 mt-1 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-auto">
                                                                                <div className="py-1">
                                                                                    {notificationMethods.map((method) => (
                                                                                        <MenuItem key={method.id}>
                                                                                            {({ active }) => (
                                                                                                <button
                                                                                                    onClick={() => handleModelChange(method.id)}
                                                                                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                                                        } block px-4 py-2 text-sm w-full text-left flex items-start justify-between`}
                                                                                                >
                                                                                                    <div>
                                                                                                        <div>{method.title}</div>
                                                                                                        <div className="text-xs text-gray-500">{method.subtitle}</div>
                                                                                                    </div>
                                                                                                    {method.id === modelName && (
                                                                                                        <CheckIcon aria-hidden="true" className="h-5 w-5 text-indigo-600" />
                                                                                                    )}
                                                                                                </button>
                                                                                            )}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </div>
                                                                            </MenuItems>
                                                                        </Transition>
                                                                    </Menu>
                                                                    <a
                                                                        href="https://openai.com/api/pricing/"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="block text-sm text-indigo-600 hover:underline mt-2"
                                                                    >
                                                                        OpenAI model pricing
                                                                    </a>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <label className="block text-sm font-medium text-gray-700 mb-1 mt-6">OpenAI API Key</label>
                                                                    <div className="flex items-center space-x-2">
                                                                        <Tooltip title={isActivated ? "To edit API key, you must deactivate it." : ""} arrow>
                                                                            <span className="w-full">
                                                                                <input
                                                                                    type="text"
                                                                                    className={`block w-full px-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 ${isActivated ? 'bg-gray-100' : ''}`}
                                                                                    value={isAnonymized && newApiKey ? anonymizeApiKey(newApiKey) : newApiKey}
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value;

                                                                                        if (isAnonymized && value.length > newApiKey.length) {
                                                                                            setNewApiKey(value.slice(-1));
                                                                                        } else if (!isAnonymized) {
                                                                                            setNewApiKey(value);
                                                                                        } else {
                                                                                            setNewApiKey('');
                                                                                        }
                                                                                    }}
                                                                                    onFocus={() => setIsAnonymized(false)}
                                                                                    disabled={isActivated}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                        <button
                                                                            type="button"
                                                                            className="flex items-center justify-center p-0 rounded-md border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-10 w-10"
                                                                            onClick={() => setIsAnonymized(!isAnonymized)}
                                                                        >
                                                                            {isAnonymized ? <EyeIcon className="h-5 w-5" /> : <EyeSlashIcon className="h-5 w-5" />}
                                                                        </button>


                                                                    </div>
                                                                    <a
                                                                        href="https://platform.openai.com/api-keys"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="block text-sm text-indigo-600 hover:underline mt-2"
                                                                    >
                                                                        How to get an OpenAI API key
                                                                    </a>
                                                                </div>

                                                                <div className="mt-2 sm:mt-2 sm:flex sm:flex-row-reverse">
                                                                    {isActivated ? (
                                                                        <button
                                                                            type="button"
                                                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                                            onClick={handleDeactivateApiKey}
                                                                        >
                                                                            Deactivate
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                                            onClick={handleActivateApiKey}
                                                                        >
                                                                            Activate
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </Dialog.Panel>
                                                        </Transition.Child>
                                                    </div>
                                                </div>
                                            </Dialog>
                                        </Transition.Root>

                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                            <span className="absolute -inset-0.5" />
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-gray-300 text-gray-900'
                                                    : 'text-gray-900 hover:bg-gray-300 hover:bg-opacity-75',
                                                'block rounded-md px-3 py-2 text-base font-medium cursor-pointer'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>

                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>


                <div className='max-w-8xl mx-auto h-full' style={{ height: 'calc(100vh - 3rem)' }}>
                    <Allotment separator={false} ref={ref}>
                        {/* Question Display Pane */}
                        <Allotment.Pane visible={shouldMaximizeDisplay} minSize={200}>
                            <QuestionDisplay
                                leetCodeQuestion={leetCodeQuestion}
                                leetCodeTitle={leetCodeTitle}
                                answerType={answerType}
                                leetCodeTags={tags}
                                leetCodeAnswer={leetCodeAnswer}
                                fetchedCode={fetchedCode}
                                questionNumber={currentQuestionIndex}
                                updateFetchedCode={updateFetchedCode}
                                handleDeleteCode={handleDeleteCode}
                                isDisplayMaximized={isDisplayMaximized}
                                setIsDisplayMaximized={setIsDisplayMaximized}
                                genSolution={genSolution}
                                genAnswerLoading={genAnswerLoading}
                                handleExportToWhiteboard={handleExportToWhiteboard}
                                createMethod={createMethod}
                                createDate={createdDate}
                                createModel={createModel}
                            />
                        </Allotment.Pane>

                        {!isDisplayMaximized && (
                            <Allotment vertical separator={false} ref={innerRef} onChange={(size) => { setIsEditorFolded(false); setIsChatFolded(false); }} >

                                {/* Question Editor Pane */}
                                <Allotment.Pane visible={!isChatMaximized} minSize={39} >
                                    <QuestionEditor
                                        selectedLanguage={selectedLanguage}
                                        onLanguageChange={handleLanguageChange}
                                        handleCodeRefresh={handleCodeRefresh}
                                        handleSaveCode={handleSaveCode}
                                        codeAnswer={codeAnswer}
                                        setCodeAnswer={setCodeAnswer}
                                        textAnswer={textAnswer}
                                        setTextAnswer={setTextAnswer}
                                        answerType={answerType}
                                        handleToggleTheme={handleToggleTheme}
                                        isDarkMode={isDarkMode}
                                        isEditorMaximized={isEditorMaximized}
                                        isEditorFolded={isEditorFolded}
                                        setIsEditorMaximized={setIsEditorMaximized}
                                        minimizeEditorPane={minimizeEditorPane}
                                        unminimizeEditorPane={unminimizeEditorPane}
                                    />
                                </Allotment.Pane>

                                {/* Chat UI Pane */}
                                <Allotment.Pane visible={!isEditorMaximized} minSize={42} preferredSize={'45%'}>
                                    <ChatUI
                                        messages={messages}
                                        setMessages={setMessages}
                                        codeContent={codeAnswer}
                                        TextContent={textAnswer}
                                        answerType={answerType}
                                        leetCodeQuestion={leetCodeQuestion}
                                        leetCodeAnswer={leetCodeAnswer}
                                        questionType='leetcode'
                                        additionalPrompt=""
                                        isChatMaximized={isChatMaximized}
                                        isChatFolded={isChatFolded}
                                        setIsChatMaximized={setIsChatMaximized}
                                        minimizeChatPane={minimizeChatPane}
                                        unminimizeChatPane={unminimizeChatPane}
                                    />
                                </Allotment.Pane>
                            </Allotment>
                        )}
                    </Allotment>


                </div>

                <MUIDialog
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="code-modal-title"
                    aria-describedby="code-modal-description"
                    PaperProps={{
                        style: {
                            maxWidth: '95%', // Adjust the maximum width as needed
                            width: '95%',    // Adjust the width as needed
                            height: '95%',    // Adjust the height as needed
                            borderRadius: '12px'
                        },
                    }}
                >
                    <IconButton
                        onClick={handleCloseModal}
                        style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            zIndex: 2, // Ensure it's above other elements
                        }}
                    >
                        <CloseIcon style={{ fontSize: '20px' }} />
                    </IconButton>
                    <DialogContent style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px', paddingBottom: '0' }}>

                        <div style={{ flex: 1, paddingRight: '0px', overflowY: 'hidden', borderRight: '1px solid #ccc' }}>
                            <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px', marginLeft: '0px', marginBottom: '0px' }}>
                                    <Typography variant="h6" style={{ marginBottom: '0', display: 'inline-block', fontWeight: 'bold' }}>
                                        Submission
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                                        <div style={{ borderLeft: '1px solid #ccc', height: '24px', marginRight: '8px' }}></div>
                                        <Tooltip title={copied ? "Copied" : "Copy Submission"}>
                                            <IconCopy onClick={handleCopy} className="cursor-pointer w-5 h-5" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            {answerType === 'Code' ? (

                                <Editor
                                    // height="100%"
                                    language={selectedLanguage}
                                    theme="light"
                                    value={codeAnswer || ''}
                                    // onChange={handleEditorChange}
                                    options={{
                                        minimap: {
                                            enabled: false,
                                        },
                                        selectOnLineNumbers: true,

                                        automaticLayout: true,
                                        scrollBeyondLastLine: false,
                                        padding: {
                                            top: 15,
                                            bottom: 55,
                                        },
                                        readOnly: true
                                    }}
                                />

                            ) : (
                                <div style={{ padding: '15px', paddingBottom: '55px', overflowY: 'auto', height: '100%' }}>

                                    <RenderHTMLContent content={textAnswer || ''} />
                                </div>
                            )}
                        </div>

                        {/* Right Half: AI Evaluation */}
                        <div style={{ flex: 1, paddingLeft: '8px', overflowY: 'hidden', position: 'relative' }}>
                            {/* Sticky header */}
                            <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '0px', marginBottom: '0px', padding: '5px' }}>
                                    <Typography variant="h6" style={{ marginBottom: '0', display: 'inline-block', fontWeight: 'bold' }}>
                                        Evaluation
                                    </Typography>
                                    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-1"
                                    >
                                        {getModelTitle(modelName)}
                                    </span>

                                </div>
                            </div>

                            <hr></hr>

                            {/* Scrollable content */}
                            <div style={{ overflowY: 'auto', maxHeight: 'calc(100% - 50px)', paddingTop: '0px' }}>
                                <Typography className="ai-message-2" variant="body1">
                                    <div className="ai-message-content-2" style={{ marginLeft: '5px', alignSelf: 'flex-start' }}>
                                        <MarkdownViewer markdownContent={aiEvalResult || ''} />
                                        {/* <RenderHTMLContent content={aiEvalResult || ''} /> */}
                                    </div>
                                </Typography>
                            </div>
                        </div>
                    </DialogContent>
                    {/* <DialogActions style={{ padding: '12px', justifyContent: 'flex-end', backgroundColor: '#f5f5f5', borderTop: '1px solid #ccc' }}>
                        <button
                            onClick={handleCloseModal}
                            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded"
                        >
                            Close
                        </button>

                    </DialogActions> */}
                </MUIDialog>

                <Transition show={maxSubmissionDialogOpen}>
                    <Dialog className="relative z-10" onClose={() => setMaxSubmissionDialogOpen(false)}>
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                        Maximum Submissions Reached
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500">
                                                            You have reached the maximum of 10 submissions for this question. Please delete a submission if you want to submit a new solution.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>



                <Transition show={apiKeyDialogOpen}>
                    <Dialog
                        className="relative z-10"
                        onClose={() => setApiKeyDialogOpen(false)}
                    >
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => setApiKeyDialogOpen(false)}
                                            >
                                                <span className="sr-only">Close</span>
                                                <IconX className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <ExclamationTriangleIcon
                                                        className="h-6 w-6 text-red-600"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title
                                                        as="h3"
                                                        className="text-base font-semibold leading-6 text-gray-900"
                                                    >
                                                        OpenAI API Key Error
                                                    </Dialog.Title>
                                                    <div className="mt-2 mb-2">
                                                        <p className="text-sm text-gray-500">
                                                            It looks like the OpenAI API key has not been activated or is not available. Please set and activate the API key to proceed.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>

                <Transition.Root show={questionListOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={setQuestionListOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 left-0 flex w-full">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="-translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-x-0"
                                        leaveTo="-translate-x-full"
                                    >
                                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700">
                                            <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                                <div className="px-4 sm:px-6">
                                                    <div className="flex items-start justify-between">
                                                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                            <button
                                                                className="text-indigo-600 hover:text-indigo-900 transition-colors duration-200"
                                                                onClick={() => {
                                                                    navigate('/questions');
                                                                    setQuestionListOpen(false);
                                                                }}
                                                            >
                                                                Question List ({originalData.length})
                                                            </button>
                                                        </Dialog.Title>
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                onClick={() => setQuestionListOpen(false)}
                                                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                <span className="absolute -inset-2.5" />
                                                                <span className="sr-only">Close panel</span>
                                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr className="my-2 border-gray-200 w-full" /> {/* Extend horizontal line */}
                                                </div>
                                                <div className="flex-1 overflow-y-auto">
                                                    {/* Mapping through originalData to display each question */}
                                                    <ul className="space-y-0 m-0 p-0">
                                                        {originalData.map((question, index) => {
                                                            const isCurrentQuestion = question.qRef === questionId;
                                                            return (
                                                                <li key={question.qRef}>
                                                                    <button
                                                                        onClick={() => {
                                                                            navigate(`/question/${question.qRef}`);
                                                                            setQuestionListOpen(false);
                                                                        }}
                                                                        className={`block w-full text-left text-gray-700 hover:text-gray-900 p-4 transition-colors duration-200 ${isCurrentQuestion ? 'bg-indigo-100' : index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                                                            } hover:bg-gray-300`}
                                                                    >
                                                                        <div className="flex justify-between items-center">
                                                                            <span className="text-sm font-medium truncate">
                                                                                {`${index + 1}. ${question.title || `Question ${index + 1}`}`}
                                                                            </span>
                                                                        </div>
                                                                    </button>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>

                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>






            </div>

        </>

    );
};

export default Question;
