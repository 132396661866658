import React, { useState, useEffect, Fragment } from 'react';
import { Disclosure, Menu, Transition, Dialog, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import {
  Bars3Icon, XMarkIcon, EyeIcon, EyeSlashIcon,
  ListBulletIcon, PlusCircleIcon, UserCircleIcon,
  BookOpenIcon, EnvelopeIcon
} from '@heroicons/react/24/outline';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import { useNavigate } from 'react-router-dom';
import { auth } from './config/firebase';
import { User, signOut } from "firebase/auth";
import { IconHelp, IconListNumbers, IconLogout, IconSettings, IconTablePlus } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import { UserIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';

const Navbar = ({ currentNav }: { currentNav: string }) => {
  const [modelName, setModelName] = useState<string>('gpt-4o-mini-2024-07-18');
  const [apiKey, setApiKey] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');
  const [isAnonymized, setIsAnonymized] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [isActivated, setIsActivated] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        const userId = authUser.uid;
        const storedApiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`) || '';
        const storedModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`) || '';
        setModelName(storedModelName);

        setApiKey(storedApiKey);
        setNewApiKey(storedApiKey);
        setIsActivated(storedApiKey !== '' && localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true');
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleActivateApiKey = () => {
    if (user) {
      const userId = user.uid;
      localStorage.setItem(`hinterviewGpt_openAi_apiKey_${userId}`, newApiKey);
      localStorage.setItem(`hinterviewGPt_apiKey_isActivated_${userId}`, 'true');
      setApiKey(newApiKey);
      setIsActivated(true);
      setIsAnonymized(true);
    }
  };

  const handleDeactivateApiKey = () => {
    if (user) {
      const userId = user.uid;
      localStorage.removeItem(`hinterviewGpt_openAi_apiKey_${userId}`);
      localStorage.setItem(`hinterviewGPt_apiKey_isActivated_${userId}`, 'false');
      setApiKey('');
      setIsActivated(false);
      setIsAnonymized(true);
    }
  };

  const anonymizeApiKey = (key: string) => {
    return key ? '*'.repeat(key.length) : '';
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setIsAnonymized(true);
  };

  const navigate = useNavigate();

  const notificationMethods = [
    { id: 'gpt-4o-mini-2024-07-18', title: 'GPT-4o mini', subtitle: 'Cost-efficient model based on gpt-4o-mini-2024-07-18.' },
    { id: 'gpt-4-turbo-2024-04-09', title: 'GPT-4 Turbo', subtitle: 'Legacy Model based on gpt-4-turbo-2024-04-09.' },

    { id: 'gpt-4o-2024-08-06', title: 'GPT-4o', subtitle: 'Most advanced model based on gpt-4o-2024-08-06.' },
  ];

  const navigation = [
    { name: 'Overview', to: '/activity', icon: BookOpenIcon, activeIcon: BookOpenIcon },
    { name: 'Question List', to: '/questions', icon: IconListNumbers, activeIcon: IconListNumbers },
    { name: 'Add Question', to: '/add', icon: IconTablePlus, activeIcon: IconTablePlus },
    { name: 'Help', to: '/contact', icon: IconHelp, activeIcon: IconHelp },
  ];

  function classNames(...classes: (string | false | null | undefined)[]): string {
    return classes.filter(Boolean).join(' ');
  }

  const logOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      navigate('/auth');
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleModelChange = (id: string) => {
    if (user) {
      const userId = user.uid;
      setModelName(id);
      localStorage.setItem(`hinterviewGpt_modelName_${userId}`, id);
    }
  };

  return (
    <Disclosure as="nav" className=" border-b z-10 relative border-b-gray-300" style={{ backgroundColor: '#f5f8fa' }}>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-14 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-10 w-auto cursor-pointer"
                    onClick={() => navigate('/activity')}
                    src={logo}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      onClick={() => navigate(item.to)}
                      className={classNames(
                        item.name === currentNav
                          ? 'border-indigo-500 text-gray-900'
                          : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-750',
                        'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium cursor-pointer'
                      )}
                      aria-current={item.name === currentNav ? 'page' : undefined}
                    >
                      {/* Add the icon next to the link */}
                      <item.icon className="h-5 w-5 mr-2" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>

              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* <div className='text-sm font-medium text-gray-700 mr-2'>
                  Model:
                </div> */}
                <Tooltip title="Change Model">
                  <Menu as="div" className="relative">
                    <div>
                      <MenuButton
                        onClick={() => setIsDialogOpen(true)}
                        className="relative flex items-center rounded-full text-sm border border-gray-300 px-2 py-2 mr-0 rounded-lg hover:bg-gray-200"
                      >
                        {isActivated ? (
                          notificationMethods.find((method) => method.id === modelName)?.title || "None"
                        ) : (
                          <span className="text-red-500">Not Activated</span>
                        )}
                        <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-500" />
                      </MenuButton>

                    </div>
                  </Menu>
                </Tooltip>
                <Menu as="div" className="relative ml-3">
                  <div>
                    <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      {user?.photoURL ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.photoURL}
                          alt=""
                        />
                      ) : (
                        <UserCircleIcon className="h-8 w-8 rounded-full text-gray-500" />
                      )}
                    </MenuButton>
                  </div>
                  <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem>
                      {({ active }) => (
                        <a
                          href=""
                          onClick={() => navigate('/account')}
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 flex items-center')}
                        >
                          <IconSettings className="h-5 w-5 mr-1 text-gray-500" aria-hidden="true" />

                          Account Settings
                        </a>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <a
                          href=""
                          onClick={logOut}
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 flex items-center')}
                        >
                          <IconLogout className="h-5 w-5 mr-1 text-gray-500 ml-0.5" aria-hidden="true" />

                          Sign out
                        </a>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  onClick={() => navigate(item.to)}
                  className={classNames(
                    item.name === currentNav
                      ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                      : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
                    'block border-l-4 py-2 pl-3 pr-4 text-base font-medium cursor-pointer'
                  )}
                  aria-current={item.name === currentNav ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  {user?.photoURL ? (
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.photoURL}
                      alt=""
                    />
                  ) : (
                    <UserCircleIcon className="h-10 w-10 rounded-full text-gray-500" />
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">{user?.displayName || 'User'}</div>
                  <div className="text-sm font-medium text-gray-500">{user?.email || 'user@example.com'}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Your Profile
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Settings
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>

          <Transition.Root show={isDialogOpen} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={closeDialog}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-20 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden h-92 rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 overflow-y-auto max-h-[90vh]">
                      <div className="absolute top-0 right-0 pt-4 pr-4">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={closeDialog}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Model Settings
                      </Dialog.Title>
                      <div className="mt-1 relative">
                        <label className="block text-sm font-medium text-gray-700 mb-1 mt-6">Select Model</label>
                        <Menu as="div" className="relative inline-block text-left w-full">
                          <div className="cursor-pointer hover:text-gray-900 rounded flex items-center justify-center">
                            <MenuButton className="inline-flex w-full justify-between items-center rounded-md px-3 py-2 bg-white text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none">
                              {notificationMethods.find((method) => method.id === modelName)?.title || 'Select Model'}
                              <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-500" />
                            </MenuButton>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <MenuItems className="absolute right-0 z-10 mt-1 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-auto">
                              <div className="py-1">
                                {notificationMethods.map((method) => (
                                  <MenuItem key={method.id}>
                                    {({ active }) => (
                                      <button
                                        onClick={() => handleModelChange(method.id)}
                                        className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                          } block px-4 py-2 text-sm w-full text-left flex items-start justify-between`}
                                      >
                                        <div>
                                          <div>{method.title}</div>
                                          <div className="text-xs text-gray-500">{method.subtitle}</div>
                                        </div>
                                        {method.id === modelName && (
                                          <CheckIcon aria-hidden="true" className="h-5 w-5 text-indigo-600" />
                                        )}
                                      </button>
                                    )}
                                  </MenuItem>
                                ))}
                              </div>
                            </MenuItems>
                          </Transition>
                        </Menu>
                        <a
                          href="https://openai.com/api/pricing/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-sm text-indigo-600 hover:underline mt-2"
                        >
                          OpenAI model pricing
                        </a>
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1 mt-6">OpenAI API Key</label>
                        <div className="flex items-center space-x-2">
                          <Tooltip title={isActivated ? "To edit API key, you must deactivate it." : ""} arrow>
                            <span className="w-full">
                              <input
                                type="text"
                                className={`block w-full px-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 ${isActivated ? 'bg-gray-100' : ''}`}
                                value={isAnonymized && newApiKey ? anonymizeApiKey(newApiKey) : newApiKey}
                                onChange={(e) => {
                                  const value = e.target.value;

                                  if (isAnonymized && value.length > newApiKey.length) {
                                    setNewApiKey(value.slice(-1));
                                  } else if (!isAnonymized) {
                                    setNewApiKey(value);
                                  } else {
                                    setNewApiKey('');
                                  }
                                }}
                                onFocus={() => setIsAnonymized(false)}
                                disabled={isActivated}
                              />
                            </span>
                          </Tooltip>
                          <button
                            type="button"
                            className="flex items-center justify-center p-0 rounded-md border border-gray-300 bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-10 w-10"
                            onClick={() => setIsAnonymized(!isAnonymized)}
                          >
                            {isAnonymized ? <EyeIcon className="h-5 w-5" /> : <EyeSlashIcon className="h-5 w-5" />}
                          </button>


                        </div>
                        <a
                          href="https://platform.openai.com/api-keys"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-sm text-indigo-600 hover:underline mt-2"
                        >
                          How to get an OpenAI API key
                        </a>
                      </div>

                      <div className="mt-2 sm:mt-2 sm:flex sm:flex-row-reverse">
                        {isActivated ? (
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleDeactivateApiKey}
                          >
                            Deactivate
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleActivateApiKey}
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
