import React, { useEffect, useState } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import { Dialog, Tab, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon, PlusIcon } from '@heroicons/react/24/outline';
import logo from './assets/logo_cropped.png';
import genQuestionScreenshot from './assets/gen_question_screenshot.png'
import aiTutorScreenshot from './assets/ai_tutor_screenshot.png'
import evalScreenshot from './assets/eval_screenshot.png'
import apiKeyScreenshot from './assets/api_key_screenshot.png'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Firestore, collection, getDocs, query, where, addDoc, onSnapshot } from 'firebase/firestore';
import { CheckIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { PlayIcon } from '@heroicons/react/24/solid';
import { CodeBracketIcon, PresentationChartLineIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import logoText from './assets/logo_text.png'
import logoLato from './assets/logo_lato.png'
import { db } from './config/firebase';
import progress from './assets/progress.png'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { AdjustmentsHorizontalIcon, ClockIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';

import { IconBrandYoutube } from '@tabler/icons-react'


const navigation = [
    { name: 'How it works', href: '/#how-it-works' },
    { name: 'Pricing', href: '/#pricing' },
    { name: 'FAQ', href: '/#faq' },
    { name: 'Blog', href: '/blog' },
];

const includedFeatures = [
    'Unlimited interview question generation.',
    'Unlimited access to AI tutor.',
    'Unlimited access to interview evaluation.',
    'Review past questions and evaluations anytime.',
]

const faqs = [
    {
        question: "Is HinterviewGPT just for software engineering interviews?",
        answer: "No! software engineering interviews are just a small subset of the types of interviews that HinterviewGPT can be used for.  HinterviewGPT can generate realistic interview questions for virtually any role.  It can also evaluate the correctness of your solution for virtually any realistic interview question."
    },
    {
        question: "How do I get an OpenAI API key?",
        answer: "To get an OpenAI API key, you need to sign up for an account on the OpenAI platform. You can do so by visiting <a href='https://platform.openai.com/signup' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>OpenAI Sign Up Page</a>. Once you have an account, navigate to the <a href='https://platform.openai.com/account/api-keys' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>API Keys Section</a> of the OpenAI dashboard. There, you can create and manage your API keys. Make sure to keep your API key secure and do not share it publicly."
    },
    {
        question: "Do you store my OpenAI API key?",
        answer: "HinterviewGPT is designed with your privacy and security in mind. Your OpenAI API key is never stored on our backend servers. Instead, it is securely stored in your browser's local storage, ensuring that only you have access to it. The OpenAI API is called directly from your browser during specific operations, including generating interview questions, using the HinterviewGPT chat, evaluating your submitted solutions, and when you choose to regenerate a solution in the 'Solution' tab on the practice page. This approach ensures that your API key remains secure while allowing HinterviewGPT to provide its full range of features.",
    },
    // {
    //     question: "Is HinterviewGPT free?",
    //     answer: "HinterviewGPT does not directly charge anything; however, when HinterviewGPT calls the OpenAI API with the provided key, OpenAI will charge you a usage-based cost. Charges are based on the model you select. You can monitor your usage by visiting OpenAI's <a href='https://platform.openai.com/account/usage' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>usage section</a>.  You can view the pricing for each model on the <a href='https://openai.com/api/pricing/' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>OpenAI API pricing</a> page.",
    // },

    {
        question: "Is HinterviewGPT ever wrong?",
        answer: "Yes, HinterviewGPT can be wrong.  It is possible for HinterviewGPT to incorrectly evaluate your solution.  HinterviewGPT's accuracy greatly depends on the model you have selected.  GPT-4o is the model that generally provides the best results.  We are confident that HinterviewGPT is accurate enough to provide a valuable study tool for practicing interview questions.",
    },
    {
        question: "Which OpenAI models does HinterviewGPT use?",
        answer: "HinterviewGPT currently offers three OpenAI models: GPT-4, GPT-4o, and GPT-4o-mini.  Each model has its own strengths and weaknesses.  You can learn more about each model by visiting the <a href='https://beta.openai.com/docs/engines' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>OpenAI Model Documentation</a>.",
    },
    {
        question: "What if I already have a question in mind that I want to practice?",
        answer: "This is fine.  Generating a question is only one way to add a question.  You can also add a question manually.  Simply provide the title, body, tags and answer type and begin practicing.",
    },
    {
        question: "Can I use Claude models with HinterviewGPT?",
        answer: "Currently, HinterviewGPT only supports OpenAI models.  However adding models from other providers is on the roadmap.",
    },
];

const features = [


    // {
    //     name: 'Enter OpenAI API Key',
    //     description: 'HinterviewGPT is built on top of the OpenAI API. Once you sign up, simply enter your OpenAI API key and start using HinterviewGPT. You can create your own OpenAI API key by visiting the OpenAI website. ',
    //     imageSrc: apiKeyScreenshot,
    //     imageAlt: 'Printed photo of bag being tossed into the sky on top of grass.',
    //     bulletPoints: [
    //         'HinterviewGPT never stores your API key',
    //         'Only pay for what you use',
    //         'Use the latest OpenAI models'
    //     ],
    //     videoUrl: 'https://www.youtube.com/embed/lMJJHa-mnUA?si=HCJSjYAs0djRv840',
    // },
    {
        name: 'Generate an interview question',
        description: 'Simply enter a short description of the general topic of the question you would like to study and HinterviewGPT will generate a realistic question that is relevant to that topic.  Already have a question in mind that you would like to practice? No problem, you can manually enter the question details as well.',
        imageSrc: genQuestionScreenshot,
        imageAlt: 'Printed photo of bag being tossed into the sky on top of grass.',
        bulletPoints: [
            'Specify role, company, and difficulty',
            'Generate diverse questions',
            'Study a wide range of topics'
        ],
        videoUrl: 'https://www.youtube.com/embed/BC4N2eQrl0w?si=gNHPdz1CDLT8oPxm'
    },
    {
        name: 'Practice with your AI tutor',
        description: "Next, begin practicing your question.  If you ever get stuck, you can ask your AI tutor for hints via the HinterviewGPT chat.  Your tutor is aware of both the question and the solution you have attempted so far so it knows exactly what you need help with.",
        imageSrc: aiTutorScreenshot,
        imageAlt: 'Double stitched black canvas hook loop.',
        bulletPoints: [
            'Get hints without the full answer',
            'Feedback to improve your solution',
            'Tailored to your question and answer'
        ],
        videoUrl: 'https://www.youtube.com/embed/8otMyyh9IJg?si=w2PLVdjjFZT7fdQC'
    },
    {
        name: 'Submit your solution',
        description: "After practicing, submit your solution for evaluation. HinterviewGPT will analyze your solution and provide feedback.  If your solution is incorrect HinterviewGPT will tell you how to fix it. All of your submissions & evaluations are saved for future reference.  You can review them at any time.",
        imageSrc: evalScreenshot,
        imageAlt: 'Black canvas body with chrome zipper and key ring.',
        bulletPoints: [
            'Submit for final evaluation',
            'Save past solutions',
            'Review anytime for improvement'
        ],
        videoUrl: 'https://www.youtube.com/embed/dUxvynpS_0g?si=x6iz42TEqtw06uNu'
    },
];

interface Price {
    id: string;  // Add id to represent the price document ID
    currency: string;
    unit_amount: number;
    interval: 'day' | 'week' | 'month' | 'year';
}


interface Product {
    name: string;
    description: string;
    prices: Price[];
    features: string[];
}

const featuresEg = [
    {
        name: 'Monitor Daily Activity',
        description: 'Keep track of the number of questions you practice each day to maintain consistency.',
        icon: ChartBarIcon,
    },
    {
        name: 'Earn Achievement Badges',
        description: 'Unlock badges as you reach milestones and stay motivated throughout your preparation.',
        icon: CheckBadgeIcon,
    },
    {
        name: 'View Detailed Statistics',
        description: 'Analyze your progress over the last month and all time to identify trends and areas for improvement.',
        icon: AdjustmentsHorizontalIcon,
    },
    {
        name: 'Review Past Performance',
        description: 'Access your history of practice sessions and evaluations anytime.',
        icon: ClockIcon,
    },
];




function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const LandingPage = () => {
    const [expanded, setExpanded] = useState<string | false>('');
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageSrc, setCurrentImageSrc] = useState('');
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState<'success' | 'error' | ''>('');
    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setHasScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const showAlert = (message: string, type: 'success' | 'error') => {
        setAlertMessage(message);
        setAlertType(type);

        // Automatically hide alert after 5 seconds
        setTimeout(() => {
            setAlertMessage('');
            setAlertType('');
        }, 5000);
    };


    const handleWatchVideo = (videoUrl: string) => {
        setCurrentVideoUrl(videoUrl);
        setIsVideoOpen(true);
    };


    useEffect(() => {
        const fetchProducts = async () => {
            const productsCollection = collection(db, 'products');
            const q = query(productsCollection, where('active', '==', true));
            const querySnapshot = await getDocs(q);

            const productsData: Product[] = [];
            for (const productDoc of querySnapshot.docs) {
                const productData = productDoc.data() as Omit<Product, 'prices'>;
                const pricesCollection = collection(productDoc.ref, 'prices');
                const pricesSnapshot = await getDocs(pricesCollection);
                const prices: Price[] = pricesSnapshot.docs.map((priceDoc) => {
                    const priceData = priceDoc.data() as Omit<Price, 'id'>;  // Exclude id
                    return {
                        id: priceDoc.id,  // Manually add the Firestore ID
                        ...priceData,     // Spread the rest of the price data
                    };
                });

                const features = productData.name === 'Bring your own key'
                    ? ['Requires OpenAI API key', 'Pay OpenAI directly for your usage', 'HinterviewGPT never stores your key', 'Access to GPT-4o and GPT-4o-mini', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                    : productData.name === 'Basic'
                        ? ['250 calls per month for GPT-4o model', '500 calls per month for GPT-4o-mini model', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                        : productData.name === 'Advanced'
                            ? ['500 calls per month for GPT-4o model', '750 calls per month for GPT-4o-mini model', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                            : ['Basic feature 1', 'Basic feature 2'];

                productsData.push({ ...productData, prices, features });
            }
            setProducts(productsData);
        };

        fetchProducts();
    }, []);


    const handleCheckout = async (priceId: string) => {
        const user = auth.currentUser;

        if (!user) {
            // If the user isn't logged in, redirect them to the login page
            window.location.href = '/auth';
            return;
        }

        const checkoutSessionRef = collection(db, 'customers', user.uid, 'checkout_sessions');

        try {
            const docRef = await addDoc(checkoutSessionRef, {
                price: priceId,
                success_url: `${window.location.origin}/success`, // Redirect to the success page after payment
                cancel_url: window.location.origin,
            });

            // Listen for the session URL from Stripe and redirect the user
            const unsubscribe = onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data()!;
                if (error) {
                    alert(`An error occurred: ${error.message}`);
                }
                if (url) {
                    window.location.assign(url); // Redirect to Stripe Checkout
                }
            });
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('An error occurred while creating the checkout session.');
        }
    };

    type AlertType = 'success' | 'error';

    const handleSubscribe = async (email: string, setAlert: (msg: string, type: AlertType) => void) => {
        if (!email) {
            setAlert("Please enter a valid email.", "error");
            return;
        }

        try {
            const mailingListCollection = collection(db, 'mailingList');
            // Check if the email already exists in the mailing list
            const q = query(mailingListCollection, where("email", "==", email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Email already exists in the collection
                setAlert("This email is already subscribed.", "error");
                return;
            }

            // If email does not exist, add it to the mailing list
            await addDoc(mailingListCollection, { email });
            setAlert("Thank you for subscribing!", "success");
        } catch (error) {
            console.error('Error adding email to mailing list:', error);
            setAlert('An error occurred while subscribing. Please try again.', "error");
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'HinterviewGPT - Generate and Practice Interview Questions with AI';

    }, []);

    return (
        <div >

            <header className={`top-0 z-50 transition-all duration-300 border-b `}>
                <nav
                    aria-label="Global"
                    className="max-w-7xl mx-auto flex items-center justify-between py-4 px-2 lg:px-4"
                >
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img alt="" src={logoLato} className="h-7 w-auto" />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                        {user ? (
                            <a
                                href="/activity"
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                App <span aria-hidden="true">&rarr;</span>
                            </a>
                        ) : (
                            <>
                                <a
                                    href="/auth?type=login"
                                    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Log In
                                </a>
                                <a
                                    href="/auth?type=register"
                                    className="ml-4 rounded-md border border-indigo-600 px-4 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Register
                                </a>
                            </>
                        )}
                    </div>
                </nav>

                {/* Mobile Menu */}
                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    alt=""
                                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    {user ? (
                                        <a
                                            href="/activity"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            App
                                        </a>
                                    ) : (
                                        <>
                                            <a
                                                href="/auth?type=login"
                                                className="rounded-md bg-indigo-600 px-4  text-base font-semibold text-white hover:bg-indigo-500"
                                            >
                                                Log In
                                            </a>
                                            <a
                                                href="/auth?type=register"
                                                className="ml-4 rounded-md border border-indigo-600 px-4 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white"
                                            >
                                                Register
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>

            <div className="relative isolate border-b" > {/* Increased padding to prevent overlap */}
                <div className="py-12 sm:py-12 relative overflow-hidden ">
                    <div className="mx-auto max-w-6xl px-4 lg:px-6 pb-12 pt-4">
                        <div className="mx-auto max-w-3xl text-center">
                            <span className="inline-flex items-center rounded-xl bg-indigo-400/10 px-2 py-1 text-sm font-bold mr-2 text-indigo-600 ring-1 ring-inset ring-indigo-400/20">
                                Your AI-Driven Interview Coach
                            </span>

                            <h1 className="text-7xl font-bold tracking-tight text-gray-900 sm:text-7xl pt-4">
                                Interview Prep Meets{" "}
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500 animate-pulse">
                                    Generative AI
                                </span>
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-800">
                                HinterviewGPT is the best platform for generating realistic interview
                                questions and practicing them with your own personal AI tutor, helping you
                                prepare for your next big opportunity.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a
                                    href={user ? "/activity" : "/auth"}
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Get started
                                </a>
                                <a
                                    href="/#how-it-works"
                                    className="text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                        <div className="mt-16 flow-root sm:mt-24">
                            <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                                <iframe
                                    src="https://player.vimeo.com/video/1012598677?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;muted=1&amp;loop=1"
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                    title="HinterviewGPT Demo"
                                    width="100%"
                                    height="700"
                                    frameBorder="2"
                                    allowFullScreen
                                    className="rounded-2xl"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* <hr className="my-8 border-gray-300" /> */}


            {/* How It Works Section */}
            <div id="how-it-works" className="scroll-mt-16 py-16 sm:py-20 border-b" style={{ backgroundColor: '#f9fafb' }}>
                <div className="mx-auto max-w-7xl lg:px-4">
                    <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
                        <div className="max-w-4xl mx-auto text-center">
                            <span className="inline-flex items-center rounded-xl bg-indigo-400/10 mb-2 px-2 py-1 text-sm font-semibold mr-2 text-indigo-600 ring-1 ring-inset ring-indigo-400/20">
                                Generate, Practice, Repeat
                            </span>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How HinterviewGPT Works</p>
                            <p className="mt-2 text-lg text-gray-600">
                                Discover how our platform generates interview questions tailored to your needs, guides you through practice sessions with AI assistance, and helps you improve with personalized feedback.
                            </p>
                        </div>
                        <div className="mt-12 space-y-16">
                            {features.map((feature, index) => (
                                <div key={feature.name} className="flex justify-center">
                                    <div className="rounded-lg  p-6 flex flex-col w-full max-w-7xl">
                                        <div className={`flex flex-col lg:flex-row lg:gap-x-12 ${index % 2 !== 0 ? 'lg:flex-row-reverse' : ''}`}>
                                            <div className="lg:w-2/5">
                                                <span className="text-indigo-600 text-lg font-semibold">Step {index + 1}</span>
                                                <h3 className="text-2xl font-bold text-gray-900">{feature.name}</h3>
                                                <p className="mt-6 mb-8 text-lg leading-8 text-gray-600">
                                                    {feature.description}
                                                </p>
                                                {/* Get started button */}
                                                <a
                                                    href={user ? "/activity" : "/auth"}
                                                    className="text-sm mt-8 font-semibold leading-6 text-indigo-600"
                                                    >
                                                    Get started <span aria-hidden="true">→</span>
                                                </a>

                                            </div>
                                            <div className="lg:w-3/5 mt-6 lg:mt-0 flex justify-center">
                                                <iframe
                                                    className="w-full h-[510px] lg:h-[425px] rounded-lg"
                                                    src={feature.videoUrl}
                                                    title="YouTube video player"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>




            <div className="overflow-hidden bg-white scroll-mt-16  py-16 sm:py-20  border-b" >
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base font-semibold leading-7 text-indigo-600">Stay on Top of Your Progress</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Track Your Activity</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    HinterviewGPT provides comprehensive tools to help you monitor your improvement over time. Earn badges, view detailed statistics, and understand your strengths and weaknesses to optimize your interview preparation.
                                </p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                    {featuresEg.map((feature) => (
                                        <div key={feature.name} className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-900">
                                                <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600" />
                                                {feature.name}
                                            </dt>{' '}
                                            <dd className="inline">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <div className="flex items-start justify-end lg:order-first">
                            <img
                                alt="Progress tracking dashboard"
                                src={progress}
                                width={2432}
                                height={1442}
                                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                            />
                        </div>
                    </div>
                </div>
            </div>




            {/* Who is HinterviewGPT For Section */}
            <div className="relative isolate py-16  sm:py-20 border-b" style={{ backgroundColor: '#f9fafb' }}>


                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="max-w-5xl mx-auto text-center">
                        <span className="inline-flex items-center rounded-xl bg-indigo-400/10 px-2 py-1 mb-4 text-sm font-semibold mr-2 text-indigo-600 ring-1 ring-inset ring-indigo-400/20">
                            Tailored for Every Role
                        </span>

                        <h2 className="text-4xl font-extrabold text-gray-900">Who is HinterviewGPT For?</h2>
                        <p className="mt-4 text-lg text-gray-600">
                            HinterviewGPT is designed for anyone preparing for an upcoming interview, regardless of industry or role. Whether you're a seasoned professional or just starting your career, our platform helps you practice and improve your interview skills.
                        </p>
                    </div>

                    {/* Grid of Roles */}
                    <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                        {/* Card 1 */}
                        <div className="flex flex-col items-center text-center bg-white p-8 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
                            <CodeBracketIcon className="h-16 w-16 text-indigo-600 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-900">Software Engineers</h3>
                            <p className="mt-2 text-base text-gray-600">
                                Hone your coding interview skills and tackle algorithmic challenges with confidence.
                            </p>
                        </div>
                        {/* Card 2 */}
                        <div className="flex flex-col items-center text-center bg-white p-8 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
                            <PresentationChartLineIcon className="h-16 w-16 text-indigo-600 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-900">Product Managers</h3>
                            <p className="mt-2 text-base text-gray-600">
                                Master product strategy and cross-functional collaboration topics for your interviews.
                            </p>
                        </div>
                        {/* Card 3 */}
                        <div className="flex flex-col items-center text-center bg-white p-8 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
                            <ChartBarIcon className="h-16 w-16 text-indigo-600 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-900">Data Analysts</h3>
                            <p className="mt-2 text-base text-gray-600">
                                Prepare for data interpretation and statistical analysis questions to stand out.
                            </p>
                        </div>
                        {/* Card 4 */}
                        <div className="flex flex-col items-center text-center bg-white p-8 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
                            <div className="flex items-center justify-center h-16 w-16 bg-indigo-100 rounded-full mb-4">
                                <PlusIcon className="h-8 w-8 text-indigo-600" aria-hidden="true" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-900">And Many More</h3>
                            <p className="mt-2 text-base text-gray-600">
                                No matter your field, HinterviewGPT can help you excel in your interviews.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <hr className="my-8 border-gray-300" /> */}



            {/* Pricing Section */}
            <div id="pricing" className="scroll-mt-16 py-16 sm:py-20 border-b">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="text-center mx-auto max-w-3xl">
                        <span className="inline-flex items-center rounded-xl bg-indigo-400/10 px-2 py-1 mb-4 text-sm font-semibold mr-2 text-indigo-600 ring-1 ring-inset ring-indigo-400/20">
                            Pick the best plan for you
                        </span>
                        <h2 className="text-4xl font-extrabold text-gray-900">Pricing</h2>
                        <p className="mt-4 text-lg text-gray-600">
                            Whether you are casually preparing or in hardcore study mode for a big interview, HinterviewGPT has a plan for you.
                        </p>
                    </div>
                    <div className="mt-2 flow-root">
                        {products.length > 0 && (
                            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                                {products.map((product, index) => (
                                    <div
                                        key={product.name}
                                        className={`ring-1 ring-gray-200 rounded-3xl p-8 xl:p-10 relative ${index === 1 ? "ring-indigo-600 border-2" : ""
                                            }`} // Highlight the second product
                                    >
                                        {index === 1 && (
                                            <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-indigo-600 text-white text-xs font-semibold px-3 py-1 rounded-full">
                                                Most Popular
                                            </div>
                                        )}

                                        <div className="flex items-center justify-between gap-x-4">
                                            <h3 className="text-gray-900 text-lg font-semibold leading-8">
                                                {product.name}
                                            </h3>
                                        </div>
                                        <p className="mt-4 text-sm leading-6 text-gray-600">
                                            {product.description}
                                        </p>
                                        <p className="mt-6 flex items-baseline gap-x-1">
                                            <span className="text-4xl font-bold tracking-tight text-gray-900">
                                                ${product.prices[0].unit_amount / 100}
                                            </span>
                                            <span className="text-sm font-semibold leading-6 text-gray-600">
                                                /month
                                            </span>
                                        </p>
                                        <button
                                            onClick={() => handleCheckout(product.prices[0].id)}
                                            className="w-full bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            {user ? "Sign Up" : "Start Free Trial"}
                                        </button>

                                        <ul
                                            role="list"
                                            className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                                        >
                                            {product.features.map((feature) => (
                                                <li key={feature} className="flex gap-x-3">
                                                    <CheckIcon
                                                        aria-hidden="true"
                                                        className="h-6 w-5 flex-none text-indigo-600"
                                                    />
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>


            {/* <hr className="my-8 border-gray-300" /> */}


            {/* FAQ Section */}
            <div id="faq" className="scroll-mt-16 py-16  sm:py-20" style={{ backgroundColor: '#f9fafb' }}>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold text-gray-900">Frequently Asked Questions</h2>
                    </div>
                    <div className="mt-12 max-w-3xl mx-auto divide-y divide-gray-200">
                        {faqs.map((faq) => (
                            <Disclosure key={faq.question} as="div" className="py-6">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between items-center w-full text-left">
                                            <span className="text-lg font-medium text-gray-900">{faq.question}</span>
                                            {open ? (
                                                <MinusSmallIcon className="w-6 h-6 text-indigo-600" />
                                            ) : (
                                                <PlusSmallIcon className="w-6 h-6 text-indigo-600" />
                                            )}
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-4 text-gray-600">
                                            <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </div>
                </div>

                {/* Image Modal */}
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
                    <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Dialog.Panel className="mx-auto max-w-4xl">
                            <div className="relative bg-white rounded-lg shadow-lg">
                                <button
                                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                <img src={currentImageSrc} alt="Enlarged" className="rounded-lg w-full h-auto" />
                            </div>
                        </Dialog.Panel>
                    </div>
                </Dialog>
            </div>
            {/* Tailwind Alert */}
            {alertMessage && (
                <div className={`fixed top-4 inset-x-0 mx-auto max-w-md px-4 py-3 rounded-md shadow-md text-white ${alertType === 'success' ? 'bg-green-600' : 'bg-red-600'}`}>
                    <p>{alertMessage}</p>
                </div>
            )}

            {/* Mailing List Subscription Section */}
            <div className="bg-indigo-600 py-16 px-6 text-white">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold mb-4">Subscribe to Our Mailing List</h2>
                    <p className="mb-8 text-lg">Stay updated with the latest features and news from HinterviewGPT.</p>
                    <div className="flex justify-center">
                        <input
                            type="email"
                            className="w-2/3 p-3 rounded-l-lg text-gray-800"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            onClick={() => handleSubscribe(email, showAlert)}
                            className="bg-indigo-800 px-5 py-3 rounded-r-lg text-white font-semibold hover:bg-indigo-700 transition"
                        >
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>
            {/* Footer */}
            <footer className="bg-gray-900 text-gray-400">
                <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8">
                    {/* Top Section: Copyright and Links */}
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        {/* Copyright */}
                        <p className="text-center md:text-left text-sm">
                            © 2024 HinterviewGPT. All rights reserved.
                        </p>
                        {/* Navigation Links */}
                        <div className="mt-4 md:mt-0 flex space-x-6">
                            <a href="/privacy" className="hover:text-white transition">
                                Privacy Policy
                            </a>
                            <a href="/tos" className="hover:text-white transition">
                                Terms of Service
                            </a>
                            <a href="mailto:support@hinterviewgpt.com" className="hover:text-white transition">
                                Contact Us
                            </a>
                        </div>
                    </div>

                    {/* Divider */}
                    <hr className="my-8 border-gray-700" />

                    {/* Bottom Section: Social Media Icons */}
                    <div className="flex justify-center space-x-6">
                        {/* Facebook */}
                        <a
                            href="https://www.youtube.com/@hinterviewgpt" // Replace with your Facebook page URL
                            className="text-gray-400 hover:text-white transition"
                            aria-label="Youtube"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconBrandYoutube className="h-6 w-6" aria-hidden="true" />
                        </a>
                        {/* Twitter */}
                        <a
                            href="https://twitter.com/yourprofile" // Replace with your Twitter profile URL
                            className="text-gray-400 hover:text-white transition"
                            aria-label="Twitter"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {/* <TwitterIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </a>
                        {/* LinkedIn */}
                        <a
                            href="https://www.linkedin.com/in/yourprofile" // Replace with your LinkedIn profile URL
                            className="text-gray-400 hover:text-white transition"
                            aria-label="LinkedIn"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {/* <LinkedInIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </a>
                        {/* Add more social icons as needed */}
                    </div>
                </div>
            </footer>
        </div>


    );
};

export default LandingPage;